<div class="card m-3">
    <div class="card-body">
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="form-group col-6">
                    <label class="mb-2">Employee Id</label>
                    <input type="text" placeholder="Enter Employee Id" formControlName="Employee_Id" maxlength="10"
                        class="form-control mb-2" [ngClass]="{ 'is-invalid': submitted && f.Employee_Id.errors }" />
                    <div *ngIf="submitted && f.Employee_Id.errors" class="invalid-feedback">
                        <div *ngIf="f.Employee_Id.errors.required">Employee_Id is required</div>
                        <div *ngIf="f.Employee_Id.errors.Employee_Id">Employee_Id must be a valid </div>
                    </div>
                </div>
                <div class="form-group col-6">
                    <label class="mb-2">Name</label>
                    <input type="text" placeholder="Enter Name" formControlName="firstName" class="form-control mb-2"
                        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                        <div *ngIf="f.firstName.errors.required">Name is required</div>
                    </div>
                </div>
            </div>
            <div class="row mt-2 mt-2">
                <div class="form-group col-6">
                    <label class="mb-2">Email</label>
                    <input type="text" placeholder="Enter Email" formControlName="email" class="form-control mb-2"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                    </div>
                </div>
                <div class="form-group col-6">
                    <label class="mb-2">Designation</label>
                    <input type="text" placeholder="Enter Designation" formControlName="Designation"
                        class="form-control mb-2" [ngClass]="{ 'is-invalid': submitted && f.Designation.errors }" />
                    <div *ngIf="submitted && f.Designation.errors" class="invalid-feedback">
                        <div *ngIf="f.Designation.errors.required">Designation is required</div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="form-group col-6">
                    <label class="mb-2">Gender</label>
                    <select formControlName="Gender" class="form-control mb-2"
                        [ngClass]="{ 'is-invalid': submitted && f.Gender.errors }">
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>

                    </select>
                    <div *ngIf="submitted && f.Gender.errors" class="invalid-feedback">
                        <div *ngIf="f.Gender.errors.required">Gender is required</div>
                    </div>
                </div>
                <div class="form-group col-6">
                    <label class="mb-2">Date Of Birth</label>
                    <input type="date" formControlName="DOB" class="form-control mb-2"
                        [ngClass]="{ 'is-invalid': submitted && f.DOB.errors }" />
                    <div *ngIf="submitted && f.DOB.errors" class="invalid-feedback">
                        <div *ngIf="f.DOB.errors.required">DOB is required</div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="form-group col-6">
                    <label class="mb-2">Mobile</label>
                    <input type="text" placeholder="Enter Mobile" formControlName="mobile" maxlength="10"
                        class="form-control " [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />
                    <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                        <div *ngIf="f.mobile.errors.required">Mobile No is required</div>
                    </div>
                </div>
                <div class="form-group col-6">
                    <label class="mb-2">One Time Password</label>
                    <input type="text" placeholder="Enter One Time Password" formControlName="otp" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.otp.errors }" />
                    <div *ngIf="submitted && f.otp.errors" class="invalid-feedback">
                        <div *ngIf="f.otp.errors.required">One Time Password is required</div>
                        <div *ngIf="f.otp.errors.minlength">min length 8</div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-3">
                <button class="btn btn-primary m-3" style="background-color:#0A51A6 ;">Register</button>
                <button class="btn btn-secondary ml-3" type="reset" (click)="onReset()">Cancel</button>
            </div>
        </form>
    </div>
</div>
<ngx-ui-loader [loaderId]="'loader-01'"></ngx-ui-loader>