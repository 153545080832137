import { Component, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as _ from "lodash";
import { ApiServicesService } from '../api-services.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BASE_URL } from '../config';
import Swal from 'sweetalert2';
import { data } from 'jquery';

@Component({
  selector: 'app-associatelist',
  templateUrl: './associatelist.component.html',
  styleUrls: ['./associatelist.component.scss']
})
export class AssociatelistComponent implements OnInit {
  ButtonControl: any = [];
  printButton: any = [];
  tablelist: any = [{ AssName: "Name", Created_date: "12-04-2023", Role: "Associate", password_date: "14-03-2023", policy: 'policy1', View: "Yes" }, { AssName: "Name1", Created_date: "12-04-2023", Role: "Associate", password_date: "14-03-2023", policy: 'policy2', View: "No" }, { AssName: "Name1", Created_date: "12-04-2023", Role: "Associate", password_date: "14-03-2023", policy: 'policy2', View: "Yes" }, { AssName: "Name1", Created_date: "12-04-2023", Role: "Associate", password_date: "14-03-2023", policy: 'policy2', View: "No" }]
  Policys: any = "All"
  option: any = true
  associateDetails: any;
  policydetails: any;
  norecord: any;
  associatepagination: any;
  allpolicys: any = 'All'
  url = BASE_URL.BASE_URL
  associaterole :any
  searchname: any

  constructor(private ApiServicesService: ApiServicesService, private ngxLoader: NgxUiLoaderService) { }
  ngOnInit(): void {
     console.log("associate",sessionStorage.getItem('associateid'));
    
  this.associaterole = sessionStorage.getItem('associateid')

    let body = {}
    this.ApiServicesService.policyget(body).subscribe((res: any) => {
      this.policydetails = res?.response
    })                        
    this.ngxLoader.startLoader('loader-01');
    this.ApiServicesService.associateDetails(body, 1).subscribe((res: any) => {
      this.ngxLoader.stopLoader('loader-01');
      this.associateDetails = res.response.docsResult
      this.associatepagination = res
    })
    
  }
  
  public openPDF1(): void {
    let DATA: any = document.getElementById('htmlData');
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 210;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('Associate_List.pdf');
    });
  }

  print_page() {
    var ButtonControl = document.getElementById('btnprint');
    this.ButtonControl.style.visibility = 'hidden';
  }
  printpage() {
    var printButton = document.getElementById('printpagebutton');
    this.printButton.style.visibility = 'hidden';
    window.print();
    this.printButton.style.visibility = 'visible';
  }

  associateDetail(num: any) {
    this.ngxLoader.startLoader('loader-01');
    let body = {
    }
    this.ApiServicesService.associateDetails(body, num).subscribe((res: any) => {
      this.ngxLoader.stopLoader('loader-01');
      this.associateDetails = res.response.docsResult
      this.associatepagination = res
    }
    )
  }
  policy() {
    this.ngxLoader.startLoader('loader-01');
    if (this.Policys == 'All') {
      this.allpolicys = 'All'
      let body = {}
      this.ApiServicesService.associateDetails(body, '').subscribe((res: any) => {
        this.ngxLoader.stopLoader('loader-01');
        this.associateDetails = res.response.docsResult
        this.associatepagination = res
        this.norecord = res
      })

    } else {
      this.ngxLoader.startLoader('loader-01');
      let body = {
        "policyId": this.Policys,
        "policyCompletion": true,
      }
      this.ApiServicesService.associateDetails(body, '').subscribe((res: any) => {
        this.associateDetails = res.response.docsResult
        this.associatepagination = res
        this.norecord = res
        this.allpolicys = ''
        this.ngxLoader.stopLoader('loader-01');
      })
    }
  }
  getCSV() {

    this.ApiServicesService.getcsv().subscribe((res: any) => {
    })



  }

  goToLink() {
    if (this.Policys == 'All') {
      window.open(this.url + 'registration/getexcelfile?id=' + this.associaterole);

    }
    else {
      window.open(this.url + 'registration/getexcelfile?policyId=' + this.Policys + '&id=' + this.associaterole);

    }
  }
  goToLinkpdf() {
    if (this.Policys == 'All') {
      window.open(this.url + 'registration/downloadpdf?id=' + this.associaterole);

    }
    else {
      window.open(this.url + 'registration/downloadpdf?policyId=' + this.Policys + '&id=' + this.associaterole + '&policyCompletion=true');

    }
  }
  associatelist() {
    console.log("onload policy this.searchname.length", this.searchname.length)
    if (this.searchname.length >= 4) {
      let body = {
        "name": this.searchname,
        "policyCompletion": true,
      }
      this.ngxLoader.startLoader('loader-05');
      this.ApiServicesService.associateDetails(body, '').subscribe((res: any) => {

        this.associateDetails = res.response.docsResult
        this.associatepagination = res
        this.norecord = res
        this.ngxLoader.stopLoader('loader-05');

      }, err => {
        this.ngxLoader.stopLoader('loader-05');

        Swal.fire({
          title: 'Error',
          text: err.error.response,
          icon: 'error',
          confirmButtonText: 'Ok'

        })


      })
    } else if (this.searchname.length == 0) {
      console.log("onload policy")
      this.policy()

    }


  }

}
