<div class="container mt-2 ml-4" *ngIf="associates == apirole">
  <div class="row ml-4">
    <div class="col-md-3 col-sm-4 item m-2 card item-card card-block pb-3" data-bs-toggle="modal"
      data-bs-target="#staticBackdrop" *ngFor="let policy of  agentpolicy?.response?.docsResult;let i=index"
      (click)="polciycard(policy,policy?.compared)">
      <h3 class="card-title text-right mt-3" style="font-weight: 500;">{{policy?.policyname}}</h3>
      <div class="ml-5">
        <div>
          <span>
            Created Date : {{policy?.createdDate | customdate}}
          </span>
          <div class="mt-1 ">
            <h4 *ngIf="policy?.compared!=0" style="color:red">Not Submitted</h4>
            <h4 *ngIf="policy?.compared==0" style="color:green">Submitted </h4>
            <span *ngIf="policy?.userDetail?.viewedPolicies?.completedDate"><span style="font-weight: 500;">Submitted on
                : </span> <span style="color: green;">{{policy?.userDetail?.viewedPolicies?.completedDate |
                customdate}}</span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-2 mt-2" *ngIf="superadmin == apirole">
  <div class="row ml-4">
    <div class="col-md-3 col-sm-2 m-3 item card item-card card-block pb-3 "
      *ngFor="let policy of  response?.response?.docsResult;let i=index">
      <div (click)="polciycard(policy,'')" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
        <div class=" item-card card-block " style="margin-right: 25px;">
          <h4 class="text-center mt-3" style="font-weight: 500;"><span>{{policy?.policyname}}</span></h4>
          <div class="mb-2" style="width: 30vh;height: 10vh;background-color: transparent;border: none;margin-left:3vh">
            <div>
              <div><span style="color:rgba(0, 0, 0, 0.673)">Created Date </span><span>: {{policy?.createdDate |
                  customdate}}</span></div>
              <div><span style="color:rgb(47, 146, 10)">Completed:</span> {{policy?.viewCount}} &nbsp;<span
                  style="color:rgba(255, 0, 0, 0.879)">Pending:</span> {{totalcount-policy?.viewCount}}</div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="policy.isActive" style="color:rgba(61, 60, 60, 0.989);margin-left: 15px;">
        <div class="d-flex flex-row ">
          <div class="p-1">Policy Status</div>
          <div class="p-1">
            <div class="can-toggle can-toggle--size-small" style="width: fit-content;">
              <input id="c" type="checkbox" [(ngModel)]="policy.isActive">
              <label for="c">
                <div class="can-toggle__switch" data-checked="active" (click)="hold(policy._id)"
                  data-unchecked="inActive"></div>
                <div class="can-toggle__label-text"></div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="policy.isActive==false" style="color:rgba(255, 0, 0, 0.879);margin-left: 15px;">
        <div class="d-flex flex-row">
          <div class="p-1">Policy Status</div>
          <div class="p-1">
            <div class="can-toggle can-toggle--size-small2" style="width: fit-content;">
              <input id="c" type="checkbox" [disabled]="true">
              <label for="c">
                <div class="can-toggle__switch" data-checked="active" data-unchecked="inActive"></div>
                <div class="can-toggle__label-text"></div>
              </label>
            </div>
          </div>
          <div class="p-1">
          </div>
        </div>
        <div>Deactivated on : {{policy.deactivatedDate | customdate}}</div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade " style="margin-left: calc(min(15vh)) !important;" id="staticBackdrop" data-bs-backdrop="false"
  data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style="padding-bottom:calc(0vh);margin-top: -20px;">
      <div class="modal-header">
        <span style="font-size: 16px;">Policy</span>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="close()" aria-label="Close"></button>
      </div>

      <div class="modal-body">
        <ngx-ui-loader [loaderId]="'loader-05'"></ngx-ui-loader>

        <pdf-viewer [src]="pdfurl" [rotation]="0" [original-size]="false" [show-all]="true" [fit-to-page]="true"
          [zoom-scale]="'page-width'" [stick-to-page]="true" [render-text]="true" [external-link-target]="'blank'"
          [autoresize]="true" [show-borders]="false" style="width: 100%; height: calc(72vh);"
          (after-load-complete)="callback($event)"></pdf-viewer>
        <span *ngIf="associates == apirole && submitcheck">

          <span class="form-check">
            <input type="checkbox" [(ngModel)]="checkbox" class="form-check-input" id="exampleCheck1">

            <label class="form-check-label" for="exampleCheck1">I have read and agreed with the terms and
              conditions.</label>
          </span>

          <button (click)="policysubmit()" class="btn btn-primary">Agree</button>
        </span>
      </div>
    </div>
  </div>
</div>
<ngx-ui-loader [loaderId]="'loader-01'"></ngx-ui-loader>
