/**
 * Angular  decorators and services
 */
import {
  Component,
  OnInit
} from '@angular/core';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { AppComponent } from '../app.component';
import { ApiServicesService } from '../api-services.service';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { saveAs } from 'file-saver';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { Role } from '../config';
interface InfoBox {
  bgClass: string;
  icon: string;
  title: string;
  subtitle: string;
}


@Component({
  selector: 'dashboard',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  colNum: number = 4;
  rowHeight = '120px';
  chartColNum = 2;
  chartRowHeight = '450px';
  cardClass = 'dash-card';
  slide = false
  mediaQueryList: any = null;
  mediaQueryMin: any = null;
  isMobile = false;
  chartColspan = 1;
  response: any;
  token = sessionStorage.getItem('token')
  policydetails: any;
  checkbox: boolean = false
  submitcheck: boolean = true
  role: any = sessionStorage.getItem('role_id')
  agentpolicy: any;
  pdfurl: any;
  totalcount: any;
  pdfurls: any;
  pdf: any;
  policyid: any;
  policyname: any;
  apirole: any;
  admin: any = Role.adminrole;
  associates: any = Role.Associate;
  superadmin: any = Role.SuperAdmin
  constructor(
    private breakpointObserver: BreakpointObserver, private ApiServicesService: ApiServicesService, private auth2: AppComponent, private ngxLoader: NgxUiLoaderService) {

    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      this.onScreensizeChange()
    });
  }
  ngOnInit() {
    this.ngxLoader.startLoader('loader-01');

    this.apirole = sessionStorage.getItem('Role')


    this.associatepolicy()
    sessionStorage.getItem('token')
    this.policy()
    this.ngxLoader.stopLoader('loader-01');
  }


  policy() {
    let body = {}
    this.ApiServicesService.policyget(body).subscribe((res: any) => {
      this.response = res
      this.totalcount = this.response.response.totalNumberOfUsers
    })
  }

  onScreensizeChange() {
    const isLess600 = this.breakpointObserver.isMatched('(max-width: 599px)');
    const isLess1000 = this.breakpointObserver.isMatched('(max-width: 959px)');
    if (isLess1000) {
      if (isLess600) {
        this.colNum = 1;
        this.chartColNum = 1;
        this.chartColspan = 1;
      }
      else {
        this.colNum = 2;
        this.chartColNum = 1;
        this.chartColspan = 2;
      }
    }
    else {
      this.colNum = 4;
      this.chartColNum = 2;
      this.chartColspan = 2;

    }
  }
  policysubmit() {
    if (this.checkbox == true) {
      let body = {
        "policyId": this.policyid,
        "policyName": this.policyname
      }
      this.ngxLoader.startLoader('loader-01');

      this.ApiServicesService.Policysubmit(body).subscribe((res: any) => {

        this.checkbox = false

        Swal.fire({
          title: 'Policy',
          text: 'Policy Submitted',
          icon: 'success',
          confirmButtonText: 'Ok'

        }).then((result) => {
          if (result.isConfirmed) {
            this.policy()

            location.reload()

          }
        });
        this.ngxLoader.stopLoader('loader-01');

      })

    }
    else {
      Swal.fire({
        title: 'Error',
        text: 'Checkbox Must be Selected',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }

  }


  close() {
    this.policydetails = 'data'
  }
  hold(id: any) {
    let body = {
      "policyId": id
    }
    Swal.fire({
      title: 'Policy',
      text: 'Are you Sure,Want to Disable Policy',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.policy()
        this.ApiServicesService.holdpolicy(body).subscribe((res: any) => {
          this.policy()
        })

      }
      else {
        this.policy()

      }
    });



  }
  polciycard(data: any, completed: any) {

    if (this.policyid != data._id) {
      this.ngxLoader.startLoader('loader-05');

    }

    this.policydetails = ''
    this.pdfurls = data
    this.policyid = data._id;
    this.policyname = data.policyname
    if (completed != 0) {
      this.submitcheck = true

    } else {
      this.submitcheck = false
    }


    console.log("******data.policyPath*****", data.policyPath)
    this.pdfurl = data.policyPath


  }


  checkthis(e: any) {
    if (e.target.scrollHeight < e.target.scrollTop + e.target.offsetHeight) {

    } else {
    }
  }

  associatepolicy() {

    this.ApiServicesService.agetpolicy().subscribe((res: any) => {
      this.agentpolicy = res

    })

  }

  callback(pdf: PDFDocumentProxy) {
    this.ngxLoader.stopLoader('loader-05');
  }
}
