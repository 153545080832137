import { Component, Input, OnInit } from '@angular/core';
import { GettersetterService } from '../gettersetter.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  NgForm,
} from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ApiServicesService } from '../api-services.service';

import Swal from 'sweetalert2';

import { VALIDATIONS } from '../config';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ComponentComponent } from '../component/component.component';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  @Input() data:any

  edit!: FormGroup;
  record:any;
  gender: any = ["Male", "Female", "Others"]
  submitted = false;
  // registerForm: FormGroup;
  editForm: FormGroup;
  constructor(public formBuilder: FormBuilder, private ApiServicesService: ApiServicesService, private ngxLoader: NgxUiLoaderService,private GettersetterService:GettersetterService) { }
  ngOnInit(): void {
    this.edit = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(VALIDATIONS.USERNAME), Validators.minLength(8)]],
      employeeId: ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/), Validators.minLength(8)]],
      dateOfBirth: ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/), Validators.minLength(5)]],
      email: ['', [Validators.required, Validators.pattern(VALIDATIONS.EMAIL)]],
      designation: ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/), Validators.minLength(6)]],
      gender: ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/), Validators.minLength(6)]],
      mobile: ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/), Validators.minLength(8)]],
      oneTimePassword: ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/), Validators.minLength(8)]],
    })
    this.editForm = this.formBuilder.group({
      Gender: [, Validators.required],
      firstName: ['', Validators.required],
      Designation: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      DOB: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.pattern(VALIDATIONS.MOBILE), Validators.minLength(10)]],
      Employee_Id: ['', [Validators.required, Validators.minLength(10)]],
      otp: ['', [Validators.required, Validators.minLength(8)]],
    },);

    console.log(" this.record ,", this.data );
  }
  register() {
    let data = {
      "employeeId": this.edit.value.employeeId,
      "dateOfBirth": moment(this.edit.value.dateOfBirth).format('DD/MM/YYYY'),
      "email": this.edit.value.email,
      "designation": this.edit.value.designation,
      "gender": this.edit.value.gender,
      "mobile": this.edit.value.mobile,
      "name": this.edit.value.name,
      "organisationId": "1",
      "organisationName": "Bilvantis",
      "oneTimePassword": this.edit.value.oneTimePassword
    }
    this.ngxLoader.startLoader('loader-01');
    this.ApiServicesService.addadmin(data).subscribe((res: any) => {
      if (res.errorcode == '227') {
        Swal.fire({
          title: '',
          text: 'Emp ID Already Exists',
          icon: 'error',
          confirmButtonText: 'Ok'

        })
        this.ngxLoader.stopLoader('loader-01');
      } else {
        Swal.fire({
          title: '',
          text: 'Associate added Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {

          }
        });
        this.ngxLoader.stopLoader('loader-01');
      }
    })
  }


  get formControls() {
    return this.edit.controls;
  }


  get f() { return this.editForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.editForm.invalid) {
      return;
    }

    let data = {
      "employeeId": this.editForm.value.Employee_Id,
      "dateOfBirth": moment(this.editForm.value.DOB).format('DD/MM/YYYY'),
      "email": this.editForm.value.email,
      "designation": this.editForm.value.Designation,
      "gender": this.editForm.value.Gender,
      "mobile": this.editForm.value.mobile,
      "name": this.editForm.value.firstName,
      "organisationId": "1",
      "organisationName": "Bilvantis",
      "oneTimePassword": this.editForm.value.otp
    }
    this.ngxLoader.startLoader('loader-01');

    this.ApiServicesService.addadmin(data).subscribe((res: any) => {
      if (res.errorcode == '227') {
        Swal.fire({
          title: '',
          text: 'Emp ID Already Exists',
          icon: 'error',
          confirmButtonText: 'Ok'

        })
        this.ngxLoader.stopLoader('loader-01');

      } else {
        Swal.fire({
          title: '',
          text: 'Admin Added Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'

        }).then((result) => {
          if (result.isConfirmed) {
            // this.component.associateclick()
          }
        });
        this.ngxLoader.stopLoader('loader-01');

      }


    }, err => {


      Swal.fire({
        title: '',
        text: err.error.message,
        icon: 'error',
        confirmButtonText: 'Ok'

      })



      this.ngxLoader.stopLoader('loader-01');

    })
  }
  onReset() {
    this.submitted = false;
    this.editForm.reset();
    
  }



}
