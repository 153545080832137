<div class="card m-3">
  <div class="m-3 table-responsive">
    <div class="d-flex justify-content-between m-3">
      <div class="ml-3 ">
        <select [(ngModel)]="Policys" class="Frame-36035-BackgroundMask ml-4" style="width:150px"
          (ngModelChange)="policy()">
          <option selected>All</option>
          <option *ngFor="let policy of policydetails?.docsResult" [value]="policy._id">{{policy?.policyname}}</option>
        </select>
      </div>
      <div>

        <div>

          <input type="text" class="search" [(ngModel)]="searchname" style="margin-left: calc(30vh)"
            (keyup)="associatelist()" placeholder="Search" *ngIf="allpolicys=='All'">

          <button class="btn btn-primary" style="margin-left: calc(2vh);background-color:#0A51A6 ;"
            *ngIf="associateDetails?.length!=null" onclick="print_page()"
            (click)="goToLinkpdf()">Export&nbsp;as&nbsp;PDF</button>
          <button class="btn btn-primary" style="margin-left: calc(2vh);background-color:#0A51A6 ;"
            *ngIf="associateDetails?.length!=null" onclick="print_page()"
            (click)="goToLink()">Export&nbsp;as&nbsp;CSV</button>
        </div>

      </div>
    </div>
    <div id="htmlData">
      <table class="table   table-hover ">
        <thead class="thead-dark">
          <tr>
            <th>S.NO</th>
            <th>EMP ID</th>
            <th>Associate Name</th>
            <th>Created Date</th>
            <th>Role</th>
            <th>Password Change Date</th>
            <th *ngIf="Policys!='All'">Completed Date</th>
          </tr>
        </thead>
        <tbody *ngFor="let data of associateDetails;let i=index">
          <tr>
            <td>{{(associatepagination?.response?.currentPage-1)*10+(i+1)}}</td>
            <td>{{data?.employeeId}}</td>
            <td>{{data?.name}}</td>
            <td>{{data?.createdDate | customdate}}</td>
            <td>{{data?.roleDetails[0]?.role}}</td>
            <td *ngIf="data?.passwordDetails?.updatedDate ==null" style="color: red;">Didn't Updated Password Yet</td>
            <td *ngIf="data?.passwordDetails?.updatedDate !=null">{{data?.passwordDetails?.updatedDate | customdate}}
            </td>
            <td *ngIf="allpolicys!='All'"> {{data?.viewedPolicies[0]?.completedDate | customdate}}</td>
          </tr>
        </tbody>
      </table>
    </div>




    <h1 class="text-center" style="color: black;" *ngIf="norecord?.errorcode=='227'">No Records Found</h1>


    <div style="float: right;margin-bottom: 1vh;">


      <button type="button"
        *ngIf="associatepagination?.response?.totalPages>1 && associatepagination?.response?.currentPage!=1"
        class="buttonbtn mb-5" (click)="associateDetail(associatepagination?.response.currentPage-1)">
        Previous
      </button> &nbsp;

      <button type="button"
        *ngIf="associatepagination?.response?.totalPages>1 && associatepagination?.response.totalPages !=associatepagination?.response.currentPage"
        class="buttonbtn mb-5" (click)="associateDetail(associatepagination?.response.currentPage+1)">
        Next
      </button>

    </div>
  </div>
  <ngx-ui-loader [loaderId]="'loader-01'"></ngx-ui-loader>


  <ngx-ui-loader [loaderId]="'loader-02'"></ngx-ui-loader>

  <ngx-ui-loader [loaderId]="'loader-05'"></ngx-ui-loader>
</div>