export const BASE_URL = {
  // BASE_URL: 'https://updates-medivers-backend-code-q6rvacjcra-el.a.run.app/v0.1',
  BASE_URL: 'https://intranet-backend-repo-ksfgonz3qq-uc.a.run.app/v0.1/',
  // BASE_URL: 'http://192.168.20.200:1011/v0.1/', 

}

export const Role = {
  adminrole: 'Admin',
  Associate: 'Associate',
  SuperAdmin: 'Super Admin'
}

export const VALIDATIONS = {
  USERNAME: new RegExp(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/),
  EMAIL: new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"),
  MOBILE: new RegExp("^((\\+91-?)|0)?[1-9][0-9]{9}$"),
};
