import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
@Pipe({
  name: 'customdate'
})
export class CustomdatePipe implements PipeTransform {
  constructor(public datepipe:DatePipe) { }
  transform(value: any, args?: any): any {
    if (value) {
      let formatDate = moment.utc(value)
      let dateformate= formatDate.format("DD-MM-YYYY");
      return dateformate;
    }
    return value;
  }
}