import { Injectable } from '@angular/core';
import { EditComponent } from './edit/edit.component';
import { AssociatesListsComponent } from './associates-lists/associates-lists.component';

@Injectable({
  providedIn: 'root'
})
export class GettersetterService {
  public propertyformData: any;
  public data:any;
  constructor() { 
    
  }

  // Define a setter for the form data
  setformData(data:any) {
    console.log("data**************",data);
    this.propertyformData = data;
    console.log(" this.propertyformData", this.propertyformData);
    
  }
  getformData() {
    return this.propertyformData;
  }

}
