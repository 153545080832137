import { Component, OnInit } from '@angular/core';
import { ApiServicesService } from '../api-services.service';


@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {
  empId:any
  email:any
  constructor(private ApiServicesService:ApiServicesService) { }

  ngOnInit(): void {
  }




}
