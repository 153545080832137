import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { GettersetterService } from '../gettersetter.service';
import * as _ from "lodash";
import { ApiServicesService } from '../api-services.service';
import { BASE_URL, VALIDATIONS } from '../config';
import Swal from 'sweetalert2';
import { Data, Router } from '@angular/router';
import { data } from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ComponentComponent } from '../component/component.component';
import { log } from 'console';
import { DatePipe, formatDate } from '@angular/common';
import * as bootstrap from 'bootstrap';
import { Offcanvas } from 'bootstrap';


@Component({
  selector: 'app-associates-lists',
  templateUrl: './associates-lists.component.html',
  styleUrls: ['./associates-lists.component.scss']
})
export class AssociatesListsComponent implements OnInit {
  ButtonControl: any = [];
  printButton: any = [];
  tablelist: any = [{ AssName: "Name", Created_date: "12-04-2023", Role: "Associate", password_date: "14-03-2023", policy: 'policy1', View: "Yes" }, { AssName: "Name1", Created_date: "12-04-2023", Role: "Associate", password_date: "14-03-2023", policy: 'policy2', View: "No" }, { AssName: "Name1", Created_date: "12-04-2023", Role: "Associate", password_date: "14-03-2023", policy: 'policy2', View: "Yes" }, { AssName: "Name1", Created_date: "12-04-2023", Role: "Associate", password_date: "14-03-2023", policy: 'policy2', View: "No" }]
  Policys: any = "All"
  option: any = true
  associateDetails: any;
  policydetails: any;
  norecord: any;
  associatepagination: any;
  activeassociatelistDetails:any
  allpolicys: any = 'All'
  url = BASE_URL.BASE_URL
  associaterole :any
  searchname: any
  isEdit: any;
  deleteData:any ;
  editdata: any;
  submitted = false;
  editform:FormGroup;
  @ViewChild('myOffcanvas') myOffcanvas!: ElementRef;
  offcanvasElement = document.getElementById('offcanvasRight');
  inputag: any;
  innerheight: any;
  innerWidth: any;
  
  constructor( private datePipe: DatePipe,public formBuilder: FormBuilder,private ApiServicesService: ApiServicesService, private ngxLoader: NgxUiLoaderService,private router:Router, private GettersetterService:GettersetterService, private component: ComponentComponent) { 
    this.form();
    this.formtwo()
  }
  handleOffcanvasHidden(event: any) {
    console.log("event",event);
    
  }
  ngOnInit(): void {
    console.log("associate",sessionStorage.getItem('associateid'));
   
    
    
    this.associaterole = sessionStorage.getItem('associateid')

    let body = {}
    let query_param = {
      "is_delete":false
    }
    this.ApiServicesService.policyget(body).subscribe((res: any) => {
      this.policydetails = res?.response
    })                        
    this.ngxLoader.startLoader('loader-01');
    this.ApiServicesService.activeassociatedetails(body, 1, query_param).subscribe((res: any) => {
      this.ngxLoader.stopLoader('loader-01');
      this.associateDetails = res.response.docsResult
      console.log("associateDetails>>>",this.associateDetails);
      
      this.associatepagination = res
    })
     

  }
 
  public openPDF1(): void {
    let DATA: any = document.getElementById('htmlData');
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 210;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('Associate_List.pdf');
    });
  }
  print_page() {
    var ButtonControl = document.getElementById('btnprint');
    this.ButtonControl.style.visibility = 'hidden';
  }
  printpage() {
    var printButton = document.getElementById('printpagebutton');
    this.printButton.style.visibility = 'hidden';
    window.print();
    this.printButton.style.visibility = 'visible';
  }

  associateDetail(num: any) {
    this.ngxLoader.startLoader('loader-01');
    let body = {
    }
    console.log("num",num);
    this.ApiServicesService.associateDetails(body, num).subscribe((res: any) => {
      this.ngxLoader.stopLoader('loader-01');
      this.associateDetails = res.response.docsResult
      this.associatepagination = res
     
      
    }
    )
  }
  activeassociatelistDetail(num: any) {
    this.ngxLoader.startLoader('loader-01');
    let body = {
    }
    console.log("num",num);
    this.ApiServicesService.activeassociatelistDetails(body, num).subscribe((res: any) => {
      this.ngxLoader.stopLoader('loader-01');
      this.associateDetails = res.response.docsResult
      this.associatepagination = res
      
    }
    )
  }
  activeassociate(){
      let body = {}
      let query_param = {
        "is_delete":false
      }
      this.ApiServicesService.activeassociatedetails(body,'', query_param).subscribe((res: any) => {
        this.ngxLoader.stopLoader('loader-01');
        this.associateDetails = res.response.docsResult
        this.associatepagination = res
        this.norecord = res
      })
  }
  policy() {
    this.ngxLoader.startLoader('loader-01');
    if (this.Policys == 'All') {
      this.allpolicys = 'All'
      let body = {}
      this.ApiServicesService.associateDetails(body, '').subscribe((res: any) => {
        this.ngxLoader.stopLoader('loader-01');
        this.associateDetails = res.response.docsResult
        this.associatepagination = res
        this.norecord = res
      })

    } else {
      this.ngxLoader.startLoader('loader-01');
      let body = {
        "policyId": this.Policys,
        "policyCompletion": true,
      }
      this.ApiServicesService.associateDetails(body, '').subscribe((res: any) => {
        this.associateDetails = res.response.docsResult
        this.associatepagination = res
        this.norecord = res
        this.allpolicys = ''
        this.ngxLoader.stopLoader('loader-01');
      })
    }
  }
  
  associatpdf() {
    let query_param = {
      "id":sessionStorage.getItem('associateid'),
      "is_delete":false
    }
    window.open(this.url +   `registration/associatelistdownpdf?id=${query_param.id}&is_delete=${query_param.is_delete}`);
  
  }

  associatCSV() {
    let query_param = {
      "id":sessionStorage.getItem('associateid'),
      "is_delete":false
    }
    window.open(this.url +   `registration/associatelistdownexcel?id=${query_param.id}&is_delete=${query_param.is_delete}`);
  }
  
  activeassociatelist() {
   
    if (this.searchname.length >= 4) {
      let body = {
        "search_key": this.searchname
      }
    
      let query_param = {
        "is_delete":false
      }
      this.ngxLoader.startLoader('loader-05');
      this.ApiServicesService.activeassociatedetails(body,'', query_param).subscribe((res: any) => {
        this.associateDetails = res.response.docsResult
        this.associatepagination = res
        this.norecord = res
        this.ngxLoader.stopLoader('loader-05');
        

      }, err => {
        this.ngxLoader.stopLoader('loader-05');

        Swal.fire({
          title: 'Error',
          text: err.error.response,
          icon: 'error',
          confirmButtonText: 'Ok'

        })
      })
    } else if (this.searchname.length == 0) {
      
      this.activeassociate()

    }

  }
  edits(data: any){
    document.getElementById('offcanvasRight').classList.remove('d-none')
    this.editdata=null
    this.editdata=data
  
    
this.setvalues()
 

   
  }   

  formatDatefff(inputDateStr: string): string {
    const inputDate = new Date(inputDateStr);
    return this.datePipe.transform(inputDate, 'mm-dd-YYYY');
  }
  Delete(data: any) { 
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: " #013579",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteItem(data);;
        this.searchname ='';
        this.ApiServicesService.deleteassociatelist(data?._id).subscribe((res: any) => {
          this.ngOnInit()

        })
      }
    });
  }
  deleteItem(data: any) {
    
  }
formtwo(){
    this.editform = this.formBuilder.group({
      Gender: [, Validators.required],
      firstName: ['', Validators.required],
      Designation: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      DOB: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.pattern(VALIDATIONS.MOBILE), Validators.minLength(10)]],
      Employee_Id: ['', [Validators.required, Validators.minLength(10)]],

    },);
  }
  setvalues(){
    this.editform.controls['Employee_Id'].setValue(this.editdata?.employeeId);
    this.editform.controls['firstName'].setValue(this.editdata?.name);
    this.editform.controls['email'].setValue(this.editdata?.email);
    this.editform.controls['Designation'].setValue(this.editdata?.designation);
    this.editform.controls['Gender'].setValue(this.editdata?.gender);
    const parts = this.editdata?.dateOfBirth.split('/');
    const formattedDate1 = `${parts[2]}-${parts[1]}-${parts[0]}`;
    
    console.log("dob",formattedDate1);
    
    this.editform.controls['DOB'].setValue(formattedDate1
      );
    this.editform.controls['mobile'].setValue(this.editdata?.mobile);
    

  }
  form(){
    this.editform = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(VALIDATIONS.USERNAME), Validators.minLength(8)]],
      employeeId: ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/), Validators.minLength(8)]],
      dateOfBirth: ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/), Validators.minLength(5)]],
      email: ['', [Validators.required, Validators.pattern(VALIDATIONS.EMAIL)]],
      designation: ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/), Validators.minLength(6)]],
      gender: ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/), Validators.minLength(6)]],
      mobile: ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/), Validators.minLength(8)]],
      oneTimePassword: ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/), Validators.minLength(8)]],
    })
  }
  register() {
    console.log("this.editform.value......",this.editform.value);
    
    let data = {
      "employeeId": this.editform.value.employeeId,
      "dateOfBirth":moment(this.editform.value.dateOfBirth).format('DD/MM/YYYY'),
      "email": this.editform.value.email,
      "designation": this.editform.value.designation,
      "gender": this.editform.value.gender,
      "mobile": this.editform.value.mobile,
      "name": this.editform.value.name,
      "organisationId": "1",
      "organisationName": "Bilvantis"
   
    }
   
    let query_param = {}
    this.ngxLoader.startLoader('loader-01');
    this.ApiServicesService.editassociate(data,query_param).subscribe((res: any) => {
      if (res.errorcode == '227') {
        Swal.fire({
          title: '',
          text: 'Emp ID Already Exists',
          icon: 'error',
          confirmButtonText: 'Ok'

        })
        this.ngxLoader.stopLoader('loader-01');
      } else {
        Swal.fire({
          title: '',
          text: 'Associate Edited Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            this.component.assliststab()
           
            
          }
        });
        this.ngxLoader.stopLoader('loader-01');
      }
    })
  }

  get formControls() {
    return this.editform.controls;
  }
  get f() { return this.editform.controls; }
  
closeOffcanvas() {
  const offcanvasElement = this.myOffcanvas.nativeElement;
  const offcanvas = new bootstrap.Offcanvas(offcanvasElement);
  offcanvas.hide();
}
  onSubmit() {
    this.submitted = true;
    if (this.editform.invalid) {
      return;
    }
    let data = {
      "employeeId": this.editform.value.Employee_Id,
      "dateOfBirth":moment(this.editform.value.DOB).format('DD/MM/YYYY'),
      "email": this.editform.value.email,
      "designation": this.editform.value.Designation,
      "gender": this.editform.value.Gender,
      "mobile": this.editform.value.mobile,
      "name": this.editform.value.firstName,
      "organisationId": "1",
      "organisationName": "Bilvantis",
      // "oneTimePassword": this.editform.value.otp
    }
    this.ngxLoader.startLoader('loader-01');
    let query_param = {
      "type": 'edit',
       "_id": this.editdata._id,
      "user_Id_Number": sessionStorage.getItem('Id')
    }
    this.ApiServicesService.editassociate(data,query_param).subscribe((res: any) => {
      if (res.errorcode == '227') {
        Swal.fire({
          title: '',
          text: 'Emp ID Already Exists',
          icon: 'error',
          confirmButtonText: 'Ok'

        })
        this.ngxLoader.stopLoader('loader-01');

      } else { 
        Swal.fire({
          title: '',
          text: 'Associate edited Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'

        }).then((result) => {
          if (result.isConfirmed) {
            document.getElementById('offcanvasRight').classList.add('d-none')
          const offcanvas = new Offcanvas('#offcanvasRight');
          offcanvas.hide()
          this.component.assliststab()
          this.associateDetail(1);
          this.searchname ='';
          }
        });
        this.ngxLoader.stopLoader('loader-01');
      }
    }, err => {
      Swal.fire({
        title: '',
        text: err.error.message,
        icon: 'error',
        confirmButtonText: 'Ok'

      })
      this.ngxLoader.stopLoader('loader-01');

    })
  }
  onReset() {
    this.submitted = false;
    this.editform.reset(); 
  }
  }
 
  
