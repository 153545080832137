<div class="main">
  <div style="height: 120px;" class=""><svg viewBox="0 0 500 150" preserveAspectRatio="none"
      style="height: 100%; width: 100%;">
      <path d="M0.00,49.98 C149.99,150.00 271.49,-49.98 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
        style="stroke: none; fill: #08f;"></path>
    </svg></div>

  <div class="d-flex flex-column text-center logo">
    <div class="p-2 login "> <img src="../../assets/img/logo.png" style="width: 65vh" />
    </div>

    <div class="p-2">

      <div id="container" class="container">
        <mat-card class="login-card login p-5">
          <form [formGroup]="registerForm" *ngIf="logincard==false && temppassword==true">
            <mat-form-field style="width: 90%;">
              <input type="text" matInput placeholder="Enter Employee Id" value="{{empid}}" readonly maxlength="10" />
            </mat-form-field>

            <mat-form-field style="width: 90%;">
              <input type="password" matInput placeholder="Enter One Time Password" #oldpassword
                formControlName="oldpassword" [ngClass]="{ 'is-invalid': submitted && f.oldpassword.errors }" />
              <mat-icon style="cursor: pointer" matSuffix (mousedown)="oldpassword.type = 'text'"
                (mouseup)="oldpassword.type = 'password'" [inline]="true">
                {{ oldpassword.type == "password" ? "visibility_off" : "visibility" }}
              </mat-icon>
            </mat-form-field>



            <div *ngIf="submitted && f.oldpassword.errors" class="invalid-feedback">
              <div *ngIf="f.oldpassword.errors.required">oldpassword is required</div>
              <div *ngIf="f.oldpassword.errors.oldpassword">Employee_Id must be a valid </div>
              <div *ngIf="f.oldpassword.errors.oldpassword">min length 8</div>

            </div>
            <div>
              <mat-form-field style="width: 90%;">
                <input type="password" placeholder="Enter New Password" #newpassword formControlName="newpassword"
                  matInput [ngClass]="{ 'is-invalid': submitted && f.newpassword.errors }" />

                <mat-icon style="cursor: pointer" matSuffix (mousedown)="newpassword.type = 'text'"
                  (mouseup)="newpassword.type = 'password'" [inline]="true">
                  {{ newpassword.type == "password" ? "visibility_off" : "visibility" }}
                </mat-icon>



              </mat-form-field>

              <div *ngIf="submitted && f.newpassword.errors" class="invalid-feedback">
                <div *ngIf="f.newpassword.errors.required">new password is required</div>
                <div *ngIf="f.newpassword.errors.newpassword">min length 8</div>
                <div *ngIf="f.newpassword.errors.minlength">Password must be at least 6 characters</div>
                <div *ngIf="f.newpassword.errors.newpassword">Employee_Id must be a valid </div>
              </div>



            </div>
            <div>

              <mat-form-field style="width: 90%;">
                <input type="password" placeholder="Enter Confirm Password" formControlName="confirmpassword"
                  #confirmpassword matInput
                  [ngClass]="{ 'is-invalid': submitted && f.confirmpassword.errors || registerForm.value.newpassword!=registerForm.value.confirmpassword }" />
                <mat-icon style="cursor: pointer" matSuffix (mousedown)="confirmpassword.type = 'text'"
                  (mouseup)="confirmpassword.type = 'password'" [inline]="true">
                  {{ confirmpassword.type == "password" ? "visibility_off" : "visibility" }}
                </mat-icon>

              </mat-form-field>

              <div *ngIf="submitted && f.confirmpassword.errors" class="invalid-feedback">
                <div *ngIf="f.confirmpassword.errors.required">Confirm Password is required</div>
                <div *ngIf="f.confirmpassword.errors.confirmpassword">Confirm Password must be a valid </div>
              </div>



              <div
                *ngIf="registerForm.value.newpassword!=registerForm.value.confirmpassword && registerForm.value.confirmpassword.length>0"
                style="color:red">Confirm Password is Not Matched with new password</div>
            </div>
            <button type="button" (click)="changep()" class="btn btn-primary m-3 loginbtn"
              style="background-color:#0A51A6 ;">Submit</button>
          </form>
          <mat-card-content class="field-item">
            <div class="ml-5">

            </div>
          </mat-card-content>
          <form [formGroup]="forgotpassword" *ngIf="this.logincard==false  && temppassword==false">
            <mat-card-header class="mt-2">
              <div mat-card-avatar class="example-header-image ml-5"></div>
              <h1 class="text-center"
                style="color: rgb(7, 0, 0);margin-left:75px;font-family:poppins,sans-serif;font-size: 26px;font-style: normal;">
                Forgot Password</h1>
            </mat-card-header>
            <input type="text" placeholder="Enter Employee ID" formControlName="empid" maxlength="10" class="form-controlfileld"
              [ngClass]="{ 'is-invalid': submitted && form.empid.errors }" />
            <div *ngIf="submitted && form.empid.errors" class="invalid-feedback">
              <div *ngIf="form.empid.errors.required">empid is required</div>

              <div *ngIf="form.empid.errors.minlength">min length 10</div>
            </div>
            <button mat-raised-button (click)="temppass()" style="color:rgb(255, 255, 255);margin-right: 12px;"
              class="login submit mt-3">Submit</button>
          </form>
          <form class="loginForm" name="form" #f="ngForm" novalidate *ngIf="logincard ">
          
            <mat-card-header class="mt-2">
              <div mat-card-avatar class="example-header-image ml-5"></div>
              <h1 class="text-center"
                style="color:#777777;margin-left: calc(14vh);font-family:poppins,sans-serif;font-size: 30px;font-style: normal; font-weight:600;">
                Employee Login</h1>
            </mat-card-header>

            <mat-card-content class="field-item">
              <mat-form-field style="display: block" [ngClass]="{  'mat-input-error': f.submitted && !username.valid }">
                <input matInput type="text" placeholder="Employee ID" class="username" name="username"
                  autocomplete="false" [(ngModel)]="model.username" #username="ngModel" required />
              </mat-form-field>
            </mat-card-content>

            <mat-card-content class="field-item">
              <mat-form-field style="display: block">
                <input matInput type="password" #passwords placeholder="Password" name="password"
                  [(ngModel)]="model.password" required />



                <mat-icon style="cursor: pointer" matSuffix (mousedown)="passwords.type = 'text'"
                  (mouseup)="passwords.type = 'password'" [inline]="true">
                  {{ passwords.type == "password" ? "visibility_off" : "visibility" }}
                </mat-icon>
              </mat-form-field>
            </mat-card-content>

            <div class="d-flex justify-content-between" style="margin-top: 5vh;">
              <div>
                <a style="cursor: pointer; color: #08f;margin-left:12px;" class="forgot" (click)="lofincard()">Forgot
                  Password</a>

              </div>
              <div>
                <button mat-raised-button (click)="login()" class="loginbtn">Login</button>

              </div>

            </div>

          </form>

        </mat-card>
      </div>
    </div>


  </div>


</div>
<ngx-ui-loader [loaderId]="'loader-01'"></ngx-ui-loader>
<br>
