import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2'
import { ApiServicesService } from '../api-services.service';
import { ComponentComponent } from '../component/component.component';
@Component({
  selector: 'app-uploadpolicy',
  templateUrl: './uploadpolicy.component.html',
  styleUrls: ['./uploadpolicy.component.scss']
})
export class UploadpolicyComponent implements OnInit {
  files: File[] = [];
  constructor(private ApiServicesService: ApiServicesService, private route: ActivatedRoute, private ngxLoader: NgxUiLoaderService,
    private router: Router, private component: ComponentComponent) { }
  Policyname: any
  ngOnInit(): void {
  }
  onSelect(event) {
    if (event.addedFiles[0].size < 5000000) {
      if (this.files.length! < 1) {
        this.files.push(...event.addedFiles);
      }
      else {
        Swal.fire('Sorry Cannot upload more than one file')
      }
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Please Upload Less than 5mb PDF',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  upload() {

    if (this.files.length > 0 && this.Policyname.length >= 6) {
      const formData = new FormData();
      formData.append('file', this.files[0]);
      formData.append('createdBy', sessionStorage.getItem('Id'));
      formData.append('policyname', this.Policyname);
      this.ngxLoader.startLoader('loader-01');

      this.ApiServicesService.uploadQBPdf(formData).subscribe((res: any) => {

        if (res.errorcode == "225") {
          this.ngxLoader.stopLoader('loader-01');

          Swal.fire({
            title: 'Error',
            text: 'Policy Name Already Exists in System',
            icon: 'error',
            confirmButtonText: 'Ok'

          })

        } else {

          this.ngxLoader.stopLoader('loader-01');
          Swal.fire({
            title: 'Upload',
            text: 'Policy Uploaded',
            icon: 'success',
            confirmButtonText: 'Ok'

          }).then((result) => {
            if (result.isConfirmed) {
              this.component.dashboardclick()

            }
          });
        }

      }, err => {
        Swal.fire({
          title: 'Error',
          text: err.error.message,
          icon: 'error',
          confirmButtonText: 'Ok'

        })
        this.ngxLoader.stopLoader('loader-01');
      })
    }
    else {
      Swal.fire({
        title: 'Error!',
        text: 'Please Fill Mandatory Details',
        icon: 'error',
        confirmButtonText: 'Ok'
      })

    }


  }


}
