import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpParams } from '@angular/common/http';
// const baseUrl = 'https://intranet-backend-ckntnwsj6q-uc.a.run.app/v0.1/';
// const baseUrl = 'http://localhost:1010/v0.1/';
const baseUrl = 'https://intranet-backend-repo-ksfgonz3qq-uc.a.run.app/v0.1/';

// const baseUrl = 'http://192.168.20.200:1011/v0.1/';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Acess-Control-Allow-Origin': '*',
        'x-intranet-token': sessionStorage.getItem('token2'),
        'role': sessionStorage.getItem('role_id')

    })
};
@Injectable({
    providedIn: 'root',
})

export class ApiServicesService {
  inactiveassociateDetails(body: {}, num: any) {
    
  }
    deleteAssociate(id: any) {
      
    }
    constructor(private http: HttpClient) { }

    Login(body: any) {
        return this.http.post(`${baseUrl}` + `login/checklogin`, body);
    }
    policyget(body: any) {
        const httpOptions2 = {
            headers: new HttpHeaders({

                'Acess-Control-Allow-Origin': '*',
                'x-intranet-token': sessionStorage.getItem('token2'),
                'role': sessionStorage.getItem('role_id')

            })
        };
        return this.http.post(`${baseUrl}` + `policies/getPolicies`, body, httpOptions2);
    }
    Logout() {
        let body = {

        }
        sessionStorage.clear()
        return this.http.post(`${baseUrl}` + `login/LogOut`, {}, httpOptions);
    }
    userdetails(body: any) {
        return this.http.post(`${baseUrl}` + `registration/userdetailsById`, body, httpOptions);
    }

    Policysubmit(body: any) {
        const httpOptions2 = {
            headers: new HttpHeaders({
                'x-intranet-token': sessionStorage.getItem('token2'),
                'role': sessionStorage.getItem('role_id')
            })
        };
        return this.http.post(`${baseUrl}` + `registration/submitPolicyCompletion`, body, httpOptions2);

    }
    associateDetails(body: any, num: any) {


        return this.http.post(`${baseUrl}` + `registration/associateDetails?num=${num}`, body, httpOptions);
    }
    inactiveassociatedetail_pagination(body: any, num: any,query_param:any) {


        return this.http.post(`${baseUrl}` + `registration/associateDetails?num=${num}&is_delete=${query_param.is_delete}`, body, httpOptions);
    }
    activeassociatelistDetails(body: any, num: any) {


        return this.http.post(`${baseUrl}` + `registration/associateDetails?num=${num}`, body, httpOptions);
    }
    activeassociatedetails(body: any, num: any,query_param:any) {
    
    //http://localhost:1011/v0.1/             registration/associateDetails?num=1&is_delete=false
        return this.http.post(`${baseUrl}` + `registration/associateDetails?num=${num}&is_delete=${query_param.is_delete}`, body, httpOptions );
    }
    inactiveassociatedetails(body: any, num: any,query_param:any) {
    
        //http://localhost:1011/v0.1/             registration/associateDetails?num=1&is_delete=false
            return this.http.post(`${baseUrl}` + `registration/associateDetails?num=${num}&is_delete=${query_param.is_delete}`, body, httpOptions );
        }


       deleteassociatelist(query_param:any) {
            return this.http.post(`${baseUrl}` + `registration/deleteassociate?_id=${query_param}`, {}, httpOptions );
        }
        
    uploadQBPdf(body: any) {
        const httpOptions2 = {
            headers: new HttpHeaders({
                'x-intranet-token': sessionStorage.getItem('token2'),

                'role': sessionStorage.getItem('role_id')
            })
        };
        return this.http.post(`${baseUrl}` + `policies/uploadQBPdf`, body, httpOptions2);

    }


    policysubmit(body: any) {
        return this.http.post(`${baseUrl}` + `registration/submitPolicyCompletion`, body, httpOptions);

    }

    agetpolicy() {
        let body = {

        }
        return this.http.post(`${baseUrl}` + `policies/getAssociatePolicies`, body, httpOptions);
    }


    Agentregister(body: any,query_param:any) {
        console.log("Agentregister",query_param);
        
       
        return this.http.post(`${baseUrl}` + `registration/register-user?adduser=${query_param.adduser}&type=${query_param.type}&user_Id_Number=${query_param.user_Id_Number} `, body, httpOptions);
    }
editassociate(body: any,query_param:any) {
       
       
        return this.http.post(`${baseUrl}` + `registration/register-user?type=${query_param.type}&_id=${query_param._id}&user_Id_Number=${query_param.user_Id_Number} `, body, httpOptions);
    }
    

    Agentpolicysget(body: any) {

        return this.http.post(`${baseUrl}` + `policies/getAssociatePolicies`, body, httpOptions);
    }

    change(body: any) {

        return this.http.post(`${baseUrl}` + `login/changePassword`, body)
    }


    holdpolicy(body: any) {

        return this.http.post(`${baseUrl}` + `policies/disablingPolicy`, body, httpOptions)
    }

    getcsv() {
        const params = new HttpParams().set('id', '63456c599159931283334a9e').set('type','csv');
  
        return this.http.get(`${baseUrl}` + `registration/getexcelfile`,{params})
    }   


    sendemail(body: any) {
        const params = new HttpParams().set('id', '63456c599159931283334a9e');

        return this.http.post(`${baseUrl}` + `registration/EmailForPemddingPolicy`,body,{params, headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Acess-Control-Allow-Origin': '*',
            'x-intranet-token': sessionStorage.getItem('token2'),
            'role': sessionStorage.getItem('role_id')
    
        })
    })
    }
    
    addadmin(body: any) {
        const params = new HttpParams().set('role', 'Admin');
        return this.http.post(`${baseUrl}` + `registration/register-Admin`, body,{params,headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Acess-Control-Allow-Origin': '*',
            'x-intranet-token': sessionStorage.getItem('token2'),
            'role': sessionStorage.getItem('role_id')
    
        })} )
    }    
    
    roles() {       
        return this.http.get(`${baseUrl}` + `roles/roles`, {})
    }

}
