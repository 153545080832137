import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard';
import { AboutComponent } from './about';
import { AuthGuard } from './_guard';
import { NotFoundPageComponent } from './notfoundpage';
import { LoginComponent } from './login';
import { LoadingComponent } from './loading';
import { ComponentComponent } from './component/component.component';
import { UploadpolicyComponent } from './uploadpolicy/uploadpolicy.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { AssociatependinglistComponent } from './associatependinglist/associatependinglist.component';
import { AddhrComponent } from './addhr/addhr.component';
import { AssociatesListsComponent } from './associates-lists/associates-lists.component';
import { InactiveAssociateslistComponent } from './inactive-associateslist/inactive-associateslist.component';
import { EditComponent } from './edit/edit.component';
import { EditassociateComponent } from './editassociate/editassociate.component';


const routes: Routes = [
  {
    path: "Dashboard",
    component: ComponentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "Addhr",
    component: AddhrComponent,

  },
  {
    path: "upload",
    component: UploadpolicyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "forgotpassword",
    component: ForgotpasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "",
    component: LoginComponent,
  },
  {
    path: "loading",
    component: LoadingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "dashboard",
    component: DashboardComponent
    , canActivate: [AuthGuard]


  },
  {
    path: "about",
    component: AboutComponent

  },
  {
    path: "associatepending",
    component: AssociatependinglistComponent
  },
  {
    path:"associateslists",component:AssociatesListsComponent
  },
  {
path:"inactive",component:InactiveAssociateslistComponent
  },
 
  {
    path:"edit",component:EditComponent
  },

  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: "**",
    component: NotFoundPageComponent
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
