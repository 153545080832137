import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { Breakpoints, BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { User } from './_models';
import { ApiServicesService } from './api-services.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnChanges, OnDestroy {
  title = 'ng crm';
  user: any = null;
  isMobile: boolean;
  mode = "side"
  uiContent = "content"
  progrssBarClass = "progress-bar";
  isloading = true;
  auth = User.isAuthenticated
  role = sessionStorage.getItem("Role_id")
  token = sessionStorage.getItem('token2')

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver, private ApiServicesService: ApiServicesService
  ) {

    this.isloading = true;

    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.isMobile = true;
        this.mode = "over"
        this.uiContent = "mobile-content"
      }
      else {
        this.isMobile = false;
        this.mode = "side"
        this.uiContent = "content"
      }
    });

    this.router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    })
      ;
  }

  ngOnChanges() {
  }


  ngOnInit(): void {



  }

  logout() {
    sessionStorage.clear()

    this.ApiServicesService.Logout().subscribe((res: any) => {
      this.router.navigate(['login']);

      User.isAuthenticated = false;
      this.auth = false;

    })

  }
  isAuth(isAuth?: any) {
    if (isAuth) {

    }
  }

  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this.progrssBarClass = "progress-bar";
      this.isloading = true;
    }
    if (event instanceof NavigationEnd) {
      this.progrssBarClass = "progress-bar-hidden";
      this.isloading = false;
    }
    if (event instanceof NavigationCancel) {
      this.progrssBarClass = "progress-bar-hidden";
      this.isloading = false;
    }
    if (event instanceof NavigationError) {
      this.progrssBarClass = "progress-bar-hidden";
      this.isloading = false;
    }

  }
  ngOnDestroy() {
    this.breakpointObserver.ngOnDestroy()
  }

}
