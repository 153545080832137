import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { BASE_URL } from '../config'; 
import { ApiServicesService } from '../api-services.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as _ from "lodash";
import { data } from 'jquery';
@Component({
  selector: 'app-inactive-associateslist',
  templateUrl: './inactive-associateslist.component.html',
  styleUrls: ['./inactive-associateslist.component.scss']
})
export class InactiveAssociateslistComponent implements OnInit {
inactivepdf() {
throw new Error('Method not implemented.');
}
  ButtonControl: any = [];
  printButton: any = [];
  tablelist: any = [{ AssName: "Name", Created_date: "12-04-2023", Role: "Associate", password_date: "14-03-2023", policy: 'policy1', View: "Yes" }, { AssName: "Name1", Created_date: "12-04-2023", Role: "Associate", password_date: "14-03-2023", policy: 'policy2', View: "No" }, { AssName: "Name1", Created_date: "12-04-2023", Role: "Associate", password_date: "14-03-2023", policy: 'policy2', View: "Yes" }, { AssName: "Name1", Created_date: "12-04-2023", Role: "Associate", password_date: "14-03-2023", policy: 'policy2', View: "No" }]
  Policys: any = "All"
  option: any = true
  associateDetails: any;
  inactiveassociatedetail:any
  policydetails: any;
  norecord: any;
  associatepagination: any;
  allpolicys: any = 'All'
  url = BASE_URL.BASE_URL
  associaterole :any
  searchname: any
  
  constructor(private ApiServicesService: ApiServicesService, private ngxLoader: NgxUiLoaderService) { }

  ngOnInit(): void {

    console.log("associate",sessionStorage.getItem('associateid'));
    
    this.associaterole = sessionStorage.getItem('associateid')
  
      let body = {}
      let query_param = {
        "is_delete":true
      }
      this.ApiServicesService.policyget(body).subscribe((res: any) => {
        this.policydetails = res?.response
      })                        
      this.ngxLoader.startLoader('loader-01');
      this.ApiServicesService.inactiveassociatedetails(body, 1,query_param).subscribe((res: any) => {
        this.ngxLoader.stopLoader('loader-01');
        this.associateDetails = res.response.docsResult
        this.associatepagination = res
      })
  }
  public openPDF1(): void {
    let DATA: any = document.getElementById('htmlData');
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 210;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('Associate_List.pdf');
    });
  }

  print_page() {
    var ButtonControl = document.getElementById('btnprint');
    this.ButtonControl.style.visibility = 'hidden';
  }
  printpage() {
    var printButton = document.getElementById('printpagebutton');
    this.printButton.style.visibility = 'hidden';
    window.print();
    this.printButton.style.visibility = 'visible';
  }

  associateDetail(num: any) {
    this.ngxLoader.startLoader('loader-01');
    let body = {
    }
    this.ApiServicesService.associateDetails(body, num).subscribe((res: any) => {
      this.ngxLoader.stopLoader('loader-01');
      this.associateDetails = res.response.docsResult
      this.associatepagination = res
     
      
    }
   
    
    )
  }
 inactiveDetail(num: any) {


 
    this.ngxLoader.startLoader('loader-01');
    let body = {}
    let query_param = {
      "is_delete":true
    }
    this.ApiServicesService.inactiveassociatedetail_pagination(body, num,query_param).subscribe((res: any) => {
      this.ngxLoader.stopLoader('loader-01');
      this.associateDetails = res.response.docsResult
      this.associatepagination = res
    }
    )
  }

  inactiveassociate(){
    let body = {  
    }
    let query_param = {
      "is_delete":true
    }
    this.ApiServicesService.inactiveassociatedetails(body,'', query_param).subscribe((res: any) => {
      this.ngxLoader.stopLoader('loader-01');
      this.associateDetails = res.response.docsResult
      this.associatepagination = res
      this.norecord = res
     
      
    })
}
  policy() {
    this.ngxLoader.startLoader('loader-01');
    if (this.Policys == 'All') {
      this.allpolicys = 'All'
      let body = {}
      this.ApiServicesService.associateDetails(body, '').subscribe((res: any) => {
        this.ngxLoader.stopLoader('loader-01');
        this.associateDetails = res.response.docsResult
        this.associatepagination = res
        this.norecord = res
      })

    } else {
      this.ngxLoader.startLoader('loader-01');
      let body = {
        "policyId": this.Policys,
        "policyCompletion": true,
      }
      this.ApiServicesService.associateDetails(body, '').subscribe((res: any) => {
        this.associateDetails = res.response.docsResult
        this.associatepagination = res
        this.norecord = res
        this.allpolicys = ''
        this.ngxLoader.stopLoader('loader-01');
      })
    }
  }
  inactiveassociatpdf() {
      
    let query_param = {
      "id":sessionStorage.getItem('associateid'),
      "is_delete":true
    }
    window.open(this.url +   `registration/associatelistdownpdf?id=${query_param.id}&is_delete=${query_param.is_delete}`);
  }
  inactiveassociatelist(page:any) {
    
    
    if (this.searchname.length >= 4) {
      let body = {
        "search_key": this.searchname
      }
      let query_param = {
        "is_delete":true
      }

      this.ApiServicesService.inactiveassociatedetails(body,'', query_param).subscribe((res: any) => {
        this.ngxLoader.stopLoader('loader-01');
        this.associateDetails = res.response.docsResult
        this.associatepagination = res
        this.norecord = res
        this.ngxLoader.stopLoader('loader-05');

      }, err => {
        this.ngxLoader.stopLoader('loader-05');

        Swal.fire({
          title: 'Error',
          text: err.error.response,
          icon: 'error',
          confirmButtonText: 'Ok'

        })


      })
    } else if (this.searchname.length == 0) {
      this.inactiveassociate()

    }


  }

}
function inactivepdf() {
  throw new Error('Function not implemented.');
}

