import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiServicesService } from '../api-services.service';

@Component({
  selector: 'app-viewprofile',
  templateUrl: './viewprofile.component.html',
  styleUrls: ['./viewprofile.component.scss']
})
export class ViewprofileComponent implements OnInit {
  userdetails: any;

  constructor(private ApiServicesService: ApiServicesService, private ngxLoader: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.ngxLoader.startLoader('loader-02');

    let body = {
      "empId": sessionStorage.getItem('empid')
    }

    this.ApiServicesService.userdetails(body).subscribe((res: any) => {

      this.userdetails = res?.response[0]
      this.ngxLoader.stopLoader('loader-02');

    })
  }

  viewprofile(){
    this.ngxLoader.startLoader('loader-02');

    let body = {
      "empId": sessionStorage.getItem('empid')
    }

    this.ApiServicesService.userdetails(body).subscribe((res: any) => {

      this.userdetails = res?.response[0]
      this.ngxLoader.stopLoader('loader-02');

    })
  }

}
