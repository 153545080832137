import {
  Component,
  EventEmitter,
  OnInit,
  Output
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiServicesService } from '../api-services.service';
import { User } from "../_models";
import { AppComponent } from "../app.component";
import Swal from "sweetalert2";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Role } from "../config";

@Component({
  selector: "login-form",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  @Output() isAuth = new EventEmitter<boolean>();
  model: any = {};
  isValidating = false;
  returnUrl: string;
  auth: any;
  logincard: boolean = true
  newpassword: any
  confirmpassword: any
  oldpassword: any
  registerForm: FormGroup;
  submitted = false
  empid: any;
  temppassword = false;
  forgotpassword: FormGroup;
  constructor(public formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ApiServicesService: ApiServicesService, private auth2: AppComponent, private ngxLoader: NgxUiLoaderService
  ) { }
  ngOnInit() {

    this.ApiServicesService.roles().subscribe((res: any) => {
      let response = res.response
      
      for (res of response) {

        if (res.role == Role.SuperAdmin) {
          sessionStorage.setItem('superadminid', res._id)

        } else if (res.role == Role.adminrole) {
          sessionStorage.setItem('adminid', res._id)

        }
        else if (res.role == Role.Associate) {
          sessionStorage.setItem('associateid', res._id)

        }
      }

    })

    this.forgotpassword = this.formBuilder.group({
      empid: ['', [Validators.required, Validators.minLength(10)]],
    })
    this.registerForm = this.formBuilder.group({
      oldpassword: ['', [Validators.required, Validators.minLength(8)]],
      newpassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmpassword: ['', [Validators.required]],
    },);
  }

  get f() { return this.registerForm.controls; }
  get form() { return this.forgotpassword.controls; }

  lofincard() {
    this.logincard = this.logincard ? false : true

  }

  temppass() {
    this.submitted = true;
    if (this.forgotpassword.invalid) {
      return;
    }
    let body = {
      "empId": this.forgotpassword.value.empid
    }
    this.ngxLoader.startLoader('loader-01');

    this.ApiServicesService.change(body).subscribe((res: any) => {
      this.ngxLoader.stopLoader('loader-01');

      Swal.fire({
        title: '',
        text: 'OTP Was Sent to Your Registered Mail ID',
        icon: 'success',
        confirmButtonText: 'Ok'

      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });

    }, err => {
      this.ngxLoader.stopLoader('loader-01');


      if (err.error.message == 'Password does not match') {
        Swal.fire({
          title: '',
          text: 'OTP already sent to your registered mail id,If you not received the mail,Please Contact Your Admin',
          icon: 'success',
          confirmButtonText: 'Ok'

        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      } else {
        Swal.fire({
          title: '',
          text: err.error.message,
          icon: 'error',
          confirmButtonText: 'Ok'

        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }

    })

  }
  login() {

    User.isAuthenticated = true
    let body = {
      "empId": this.model.username,
      "password": this.model.password
    }
    this.ngxLoader.startLoader('loader-01');

    this.ApiServicesService.Login(body).subscribe((res: any) => {

      sessionStorage.setItem('empid', res?.response?.[0]?.employeeId)
      this.empid = sessionStorage.getItem('empid')
      this.temppassword = res.response[0].passwordDetails.isTemporary

      if (res.response[0].passwordDetails.isTemporary == true) {
        this.ngxLoader.stopLoader('loader-01');

        this.logincard = false
      }
      else {

        sessionStorage.setItem("token2", res.response?.[0]?.accessToken)

        sessionStorage.setItem("role_id", res?.response?.[0]?.role[0])
        sessionStorage.setItem('empid', res?.response?.[0]?.employeeId)
        sessionStorage.setItem('Id', res.response[0]._id)
        sessionStorage.setItem("Role_id", res.response?.[0]?.role?.[0])
        sessionStorage.setItem("Role", res.response?.[0]?.rolescollection?.[0]?.role)

        this.auth2.auth = true;
        this.ngxLoader.stopLoader('loader-01');

        this.router.navigate(['Dashboard']).then(()=>{
          window.location.reload();
        })
      }
    }, err => {

      Swal.fire({
        title: '',
        text: err.error.message,
        icon: 'error',
        confirmButtonText: 'Ok'

      })
      this.ngxLoader.stopLoader('loader-01');

    })

    this.isValidating = true;

  }

  changep() {
    this.submitted = true;
    let body = {
      "empId": sessionStorage.getItem('empid'),
      "password": this.registerForm?.value.oldpassword,
      "newPassword": this.registerForm?.value.confirmpassword
    }
    if (this.registerForm.invalid || this.registerForm.value.newpassword != this.registerForm.value.confirmpassword) {
      return;
    }

    this.ngxLoader.startLoader('loader-01');

    this.ApiServicesService.change(body).subscribe((res: any) => {
      this.ngxLoader.stopLoader('loader-01');

      Swal.fire({
        title: '',
        text: 'password changed successfully',
        icon: 'success',
        confirmButtonText: 'Ok'

      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }, err => {
      this.ngxLoader.stopLoader('loader-01');
      Swal.fire({
        title: '',
        text: err.error.message,
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    })
  }
  forgotpass() {

  }
}
