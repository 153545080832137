<div class="container">
  <div class="card">
    <div class="m-1 table-responsive">
      <div class="d-flex justify-content-end  m-2">
        <div class="ml-3 ">
          <div>

            <input type="text" class="search" [(ngModel)]="searchname" style="margin-left: calc(30vh)"
              (keyup)="activeassociatelist()" placeholder="Search" *ngIf="allpolicys=='All'">

            <button class="btn btn-primary" style="margin-left: calc(2vh);background-color:#0A51A6 ;"
              *ngIf="associateDetails?.length!=null" onclick="print_page()"
              (click)="associatpdf()">Export&nbsp;as&nbsp;PDF</button>
            <button class="btn btn-primary" style="margin-left: calc(2vh);background-color:#0A51A6 ;"
              *ngIf="associateDetails?.length!=null" onclick="print_page()"
              (click)="associatCSV()">Export&nbsp;as&nbsp;CSV</button>
          </div>
        </div>
        <div>



        </div>
      </div>
      <div id="htmlData">
        <table class="table   table-hover ">
          <thead class="thead-dark">
            <tr>
              <th>S.NO</th>
              <th>EMP ID</th>
              <th>Associate Name</th>
              <th>Role</th>
              <th>Created Date</th>
              <th>Action</th>
              <th *ngIf="Policys!='All'">Completed Date</th>
            </tr>
          </thead>
          <tbody *ngFor="let data of associateDetails;let i=index">
            <tr>
              <td>{{(associatepagination?.response?.currentPage-1)*10+(i+1)}}</td>
              <td>{{data?.employeeId}}</td>
              <td>{{data?.name}}</td>
              <td>{{data?.roleDetails[0]?.role}}</td>
              <td>{{data?.createdDate | customdate}}</td>

              <td *ngIf="allpolicys!='All'"> {{data?.viewedPolicies[0]?.completedDate | customdate}}</td>
              <td>
                <div class="d-flex">

                  <div class="iconborder ml-2">

                    <div class="editicon m-2" mat-raised-button matTooltip="Delete" matTooltip="Edit"
                      (click)="edits(data)" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight">

                    </div>
                  </div>
                  <div class="iconborder" style="margin-left: 10px;">
                    <div class="deleteicon m-2" matTooltip="Delete" matTooltip="Delete" (click)="Delete(data)">
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>






        <h1 class="text-center" style="color: black;" *ngIf="norecord?.errorcode=='227'">No Records Found</h1>


        <div style="float: right;margin-bottom: 1vh;">


          <button type="button"
            *ngIf="associatepagination?.response?.totalPages>1 && associatepagination?.response?.currentPage!=1"
            class="buttonbtn mb-3" (click)="activeassociatelistDetail(associatepagination?.response.currentPage-1)">
            Previous
          </button> &nbsp;

          <button type="button"
            *ngIf="associatepagination?.response?.totalPages>1 && associatepagination?.response.totalPages !=associatepagination?.response.currentPage"
            class="buttonbtn mb-3" (click)="activeassociatelistDetail(associatepagination?.response.currentPage+1)">
            Next
          </button>

          <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel"
            data-bs-backdrop="false" #myOffcanvas (hidden.bs.offcanvas)="handleOffcanvasHidden($event)">
            <div class="offcanvas-header" style="height: 8vh;">
              <h3 class="offcanvas-title" id="offcanvasRightLabel">Edit</h3>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="bottomborder">
            </div>
            <div class="offcanvas-body">
              <div class="">
                <div class="">


                  <form [formGroup]="editform" (ngSubmit)="onSubmit()">
                    <div class="row">
                      <div class="form-group">
                        <label class="mb-2">Employee Id</label>
                        <input type="text" placeholder="Enter Employee Id" formControlName="Employee_Id" maxlength="10"
                          readonly class="form-control mb-1"
                          [ngClass]="{ 'is-invalid': submitted && f.Employee_Id.errors }" />
                        <div *ngIf="submitted && f.Employee_Id.errors" class="invalid-feedback">

                          <div *ngIf="f.Employee_Id.errors.required">Employee_Id is required</div>
                          <div *ngIf="f.Employee_Id.errors.Employee_Id">Employee_Id must be a valid </div>
                        </div>
                        <div class="form-group">
                          <label class="mb-1">Name</label>
                          <input type="text" placeholder="Enter Name" formControlName="firstName"
                            class="form-control  mb-2" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                          <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                            <div *ngIf="f.firstName.errors.required">Name is required</div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="row mt-1">
                      <div class="form-group">
                        <label class="mb-2">Email</label>
                        <input ngModel required type="text" placeholder="Enter Email" formControlName="email" readonly
                          class="form-control  mb-1" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">Email is required</div>
                          <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                        <div class="form-group">
                          <label class="mb-2">Designation</label>
                          <input type="text" placeholder="Enter Designation" formControlName="Designation"
                            class="form-control  mb-1"
                            [ngClass]="{ 'is-invalid': submitted && f.Designation.errors }" />
                          <div *ngIf="submitted && f.Designation.errors" class="invalid-feedback">
                            <div *ngIf="f.Designation.errors.required">Designation is required</div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="row ">
                      <div class="form-group">
                        <label class="mb-2">Gender</label>
                        <select formControlName="Gender" class="form-control  mb-1"
                          [ngClass]="{ 'is-invalid': submitted && f.Gender.errors }">
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>

                        </select>
                        <div *ngIf="submitted && f.Gender.errors" class="invalid-feedback">
                          <div *ngIf="f.Gender.errors.required">Gender is required</div>
                        </div>
                        <div class="form-group">


                          <label class="mb-2">Date Of Birth</label>
                          <input type="date" formControlName="DOB" class="form-control  mb-1"
                            [ngClass]="{ 'is-invalid': submitted && f.DOB.errors }" />
                          <div *ngIf="submitted && f.DOB.errors" class="invalid-feedback">
                            <div *ngIf="f.DOB.errors.required">DOB is required</div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="row mt-1">
                      <div class="form-group">
                        <label class="mb-2">Mobile</label>
                        <input type="text" placeholder="Enter Mobile" formControlName="mobile" maxlength="10"
                          class="form-control  mb-1" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />
                        <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                          <div *ngIf="f.mobile.errors.required">Mobile No is required</div>
                        </div>
                      </div>

                    </div>
                    <div class="text-center mt-1" >
                      <button class="btn btn-primary submit" style="background-color:#0A51A6 ; margin-left: 245px;">Save</button>
                      <!-- <button class="btn btn ml-5 cancel" type="button" (click)="onReset()">Cancel</button> -->
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>


        </div>
        <ngx-ui-loader [loaderId]="'loader-01'"></ngx-ui-loader>


        <ngx-ui-loader [loaderId]="'loader-02'"></ngx-ui-loader>

        <ngx-ui-loader [loaderId]="'loader-05'"></ngx-ui-loader>
      </div>
    </div>
  </div>
</div>