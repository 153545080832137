import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
      ) { }

    canActivate (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) : any {
        if (sessionStorage.getItem('role_id')) {
          return true
        }
        else{
        this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });

          return false
        }
        this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        return true;
    }
}