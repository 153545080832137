<div class="card">

  <div class="m-5">


    <div class="mt-4 d-flex">
      <div class="col-6"> <span>Name : </span>{{userdetails.name}}</div>
      <div class="col-6"><span>Email : </span>{{userdetails.email}}</div>
    </div>

    <div class="mt-4  d-flex">
      <div class="col-6"><span>EmployeeId :</span> {{userdetails.employeeId}}</div>
      <div class="col-6"><span>OrganisationName : </span>{{userdetails.organisationName}}</div>
    </div>

    <div class="mt-4  d-flex">

      <div class="col-6"><span>Mobile :</span> {{userdetails.mobile}}</div>
      <div><span>Designation : </span>{{userdetails.designation}}</div>
    </div>

  </div>
</div>


<ngx-ui-loader [loaderId]="'loader-02'"></ngx-ui-loader>