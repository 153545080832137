<div class="card">
    <div class="d-flex justify-content-between m-3">
        <div class="ml-3 ">
            <select [(ngModel)]="Policys" class="Frame-36035-BackgroundMask ml-4" style="width:150px"
                (ngModelChange)="policy()">
                <option selected>Select</option>
                <option *ngFor="let policy of policydetails?.docsResult" [value]="policy._id">{{policy?.policyname}}
                </option>
            </select>
        </div>
        <div class="d-flex mt-2" style="margin-left: calc(20vh);" *ngIf="this.Policys!='Select'">
            <div>  <button class="btn btn-primary"
                style="background-color:#0A51A6 ;" (click)="sendemail()">Trigger&nbsp;Email&nbsp;Remainder</button></div>
          <div class="ml-2" style="margin-left: calc(2vh);"> <button class="btn btn-primary"
            style="background-color:#0A51A6 ;" (click)="goToLinkpdf()">Export&nbsp;as&nbsp;PDF</button></div>
            <div class="ml-2" style="margin-left: calc(2vh);"> <button class="btn btn-primary"
                style="background-color:#0A51A6 ;" (click)="goToLink()">Export&nbsp;as&nbsp;CSV</button></div>
           
        </div>
        <div class="d-flex mt-2" style="margin-left: calc(20vh);" *ngIf="this.Policys=='Select'">
            <div>  <button class="btn "
                style="background-color:#c5c7c9 ;" disabled>Trigger&nbsp;Email&nbsp;Remainder</button></div>
          <div class="ml-2" style="margin-left: calc(2vh);"> <button class="btn " disabled
            style="background-color:#c5c7c9 ;">Export&nbsp;as&nbsp;PDF</button></div>
            <div class="ml-2" style="margin-left: calc(2vh);"> <button class="btn" disabled
                style="background-color:#c5c7c9 ;">Export&nbsp;as&nbsp;CSV</button></div>
           
        </div>
        <div>
            
        
        </div>
    </div>


<div class="m-3">
    <table class="table   table-hover " *ngIf="this.Policys!='Select'">
        <thead class="thead-dark">
        <tr><th>S.NO</th><th>EMP ID</th><th>Associate Name</th><th>Policy</th><th>Policy Created Date</th></tr></thead>
        <tbody *ngFor="let data of associateDetails;let i=index">
        <tr>
          <td>{{(associatepagination?.response?.currentPage-1)*10+(i+1)}}</td><td>{{data?.employeeId}}</td><td >{{data?.name}}</td>
          <td *ngIf="data.viewedPolicies?data.viewedPolicies:false"> {{data?.viewedPolicies[0]?.policyName}}
        </td>
        <td> {{associatepagination?.response?.policy}}
        </td>
        <td>{{data?.createdDate | customdate}}</td>
        </tr>
        </tbody>
        </table>

        <h1 class="text-center" style="color: black;" *ngIf="norecord?.errorcode=='227'">No Records Found</h1>


</div>

<div style="margin-bottom: 1vh;margin-left: calc(120vh);" *ngIf="this.Policys!='Select'" >


    <button type="button" *ngIf="associatepagination?.response?.totalPages>1 && associatepagination?.response?.currentPage!=1" class="buttonbtn mb-5" (click)="associateDetail(associatepagination?.response.currentPage-1)">
        Previous
          </button> &nbsp;
    
      <button type="button" *ngIf="associatepagination?.response?.totalPages>1 && associatepagination?.response.totalPages !=associatepagination?.response.currentPage" class="buttonbtn mb-5" (click)="associateDetail(associatepagination?.response.currentPage+1)">
    Next
      </button>
    
    </div>

<ngx-ui-loader [loaderId]="'loader-01'"></ngx-ui-loader>


</div>
