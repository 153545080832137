<h1>Upload PDF<span style="color: red;">*</span></h1>
<ngx-dropzone (change)="onSelect($event)" [accept]="'application/pdf'" [multiple]="false">
    <ngx-dropzone-label>Upload Policy PDF</ngx-dropzone-label>
    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-preview>
</ngx-dropzone>
<div class="mt-5">
    <h1>Policy Name <span style="color: red;">*</span></h1>
    <input type="text" class="Frame-36035-BackgroundMask pl-2" placeholder="Enter Policy Name"
        [(ngModel)]="Policyname" /><br>
    <div *ngIf="Policyname?.length<6 && Policyname?.length>0" style="color:Red">min length 6</div>
    <button type="button" (click)="upload()" class="buttonbtn mt-5">Upload</button>
</div>

<ngx-ui-loader [loaderId]="'loader-01'"></ngx-ui-loader>