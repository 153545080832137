import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editassociate',
  templateUrl: './editassociate.component.html',
  styleUrls: ['./editassociate.component.scss']
})
export class EditassociateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
