import { Component, OnInit, OnDestroy, OnChanges, HostListener } from '@angular/core';
import { Breakpoints, BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../_models';
import { ApiServicesService } from '../api-services.service';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Role } from '../config';
import { ViewprofileComponent } from '../viewprofile/viewprofile.component';

@Component({
  selector: 'app-component',
  templateUrl: './component.component.html',
  styleUrls: ['./component.component.scss']
})
export class ComponentComponent implements OnInit {
  title = 'ng crm';
  user: any = null;
  isMobile: boolean;
  mode = "side"
  uiContent = "content"
  progrssBarClass = "progress-bar";
  isloading = true;
  auth = User.isAuthenticated
  role = sessionStorage.getItem("Role_id")
  token = sessionStorage.getItem('token')
  userdetails: any;
  dashboard: boolean = true
  associate: boolean = false
  upload: boolean = false
  viewprofile: boolean = false
  adduser: boolean = false
  oldpassword: any
  newpassword: any
  arrows: boolean = true
  username: any;
  associatepend: boolean = false;
  addhr: boolean = false;
  apirole: any;
  admin: any = Role.adminrole;
  associates: any = Role.Associate;
  superadmin: any = Role.SuperAdmin
  asslist:boolean=false
  active :boolean=false
  innerheight:any;
  inputag:any;
  innerWidth:any;
  

  arrow() {
    this.arrows = this.arrows ? false : true

  }

  addhrtab() {
    this.associate = false
    this.dashboard = false
    this.upload = false
    this.viewprofile = false
    this.adduser = false
    this.associatepend = false
    this.addhr = true
    this.active = false
    this.asslist=false
  }
  dashboardclick() {
    this.associate = false
    this.dashboard = true
    this.upload = false
    this.viewprofile = false
    this.adduser = false
    this.associatepend = false
    this.addhr = false
    this.active = false
    this.asslist=false

  }
  assliststab(){
    this.upload = false
    this.viewprofile = false
    this.adduser = false
    this.associatepend = false
    this.addhr = false
     this.asslist=true
    this.dashboard = false
    this.associate = false
    // this.asslist=true
    this.active = false
    
  }
  associatependingtab() {
    this.associate = false
    this.dashboard = false
    this.upload = false
    this.viewprofile = false
    this.adduser = false
    this.associatepend = true
    this.addhr = false
    this.active = false
    this.asslist=false
  }
  uploadtab() {
    this.upload = true
    this.viewprofile = false
    this.adduser = false
    this.associatepend = false
    this.addhr = false
    this.asslist=false
    this.dashboard = false
    this.associate = false
    this.active = false
  }
  associateclick() {
    this.upload = false
    this.viewprofile = false
    this.adduser = false
    this.associatepend = false
    this.addhr = false
    this.asslist=false
    this.dashboard = false
    this.associate = true
    this.active = false

  }
  Viewprofile() {
    this.upload = false
    this.viewprofile = true
    this.adduser = false
    this.associatepend = false
    this.addhr = false
    this.asslist=false
    this.dashboard = false
    this.associate = false
    this.active = false
  }
  inactivetab(){
    this.upload = false
    this.viewprofile = false
    this.adduser = false
    this.associatepend = false
    this.addhr = false
    this.asslist=false
    this.dashboard = false
    this.associate = false
    this.active=true
  }
  addusertab() {
    this.upload = false
    this.viewprofile = false
    this.adduser = true
    this.associatepend = false
    this.addhr = false
    this.asslist=false
    this.dashboard = false
    this.associate = false
    this.active = false
  }
 
  constructor(
    private router: Router,

    private breakpointObserver: BreakpointObserver, private ApiServicesService: ApiServicesService, private ngxLoader: NgxUiLoaderService
    , private viewprofiles: ViewprofileComponent) {
    this.isloading = true;

    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.isMobile = true;
        this.mode = "over"
        this.uiContent = "mobile-content"
      }
      else {
        this.isMobile = false;
        this.mode = "side"
        this.uiContent = "content"
      }
    });
    breakpointObserver.ngOnDestroy()

    this.router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    })
      ;
  }

  ngOnChanges() {
  }


  ngOnInit(): void {
    this.apirole = sessionStorage.getItem('Role');

    this.ngxLoader.startLoader('loader-03');

    let body = {
      "empId": sessionStorage.getItem('empid')
    }
    this.ApiServicesService.userdetails(body).subscribe((res: any) => {
      this.userdetails = res.response
      this.username = this.userdetails[0]?.name


    })

    if (this.apirole == this.admin) {
      //  this.addusertab()
      this.assliststab()
    }
  
    this.onResize('') 
    
    this.ngxLoader.stopLoader('loader-03');

  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerheight = window.innerHeight;
    this.innerWidth = window.innerWidth;
    this.inputag = (this.innerheight - this.innerheight) + 5
  }
  logout() {
    Swal.fire({
      title: 'Logout',
      text: 'Are you sure, do you want to logout?',
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#003d7c',
      cancelButtonColor: '#d33',
      allowEscapeKey: false,
      confirmButtonText: 'Logout',
    }).then((result) => {
      if (result.isConfirmed) {
        this.ngxLoader.startLoader('loader-01');

        this.ApiServicesService.Logout().subscribe((res: any) => {
          this.ngxLoader.stopLoader('loader-01');
          sessionStorage.clear()


          this.router.navigate(['login']);
        })

      }
    });




  }



  isAuth(isAuth?: any) {
    if (isAuth) {
    }
  }

  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this.progrssBarClass = "progress-bar";
      this.isloading = true;
    }
    if (event instanceof NavigationEnd) {
      this.progrssBarClass = "progress-bar-hidden";
      this.isloading = false;
    }
    if (event instanceof NavigationCancel) {
      this.progrssBarClass = "progress-bar-hidden";
      this.isloading = false;
    }
    if (event instanceof NavigationError) {
      this.progrssBarClass = "progress-bar-hidden";
      this.isloading = false;
    }

  }


  ngOnDestroy() {
    this.breakpointObserver.ngOnDestroy()

  }


}