<div [ngClass]="uiContent" role="main">

  <mat-sidenav-container class="app-container" autosize fullscreen>
    <mat-sidenav style="width: 300px;" #sidenav id="appnav" [mode]="mode" [opened]="!isMobile">
      <div class="profile-card d-flex justify-content-around"
        style="background-color: #d6ebf6;border-radius: none;height:64px; ">

        <img src="../../assets/logo.png" />

        <mat-card-title class="align-middle mt-3 ml-4" style="padding:5px;color: white;">
    
        </mat-card-title>
      </div>
      <mat-nav-list>

        <a mat-list-item (click)="dashboardclick()" *ngIf="admin != apirole">

          <mat-icon mat-list-icon style="color: #094da3;">insert_chart_outline</mat-icon>
          <span mat-line>Dashboard</span>
        </a>
        <a mat-list-item (click)="uploadtab()" *ngIf="superadmin == apirole">

          <mat-icon style="color: #094da3;" mat-list-icon>cloud_upload</mat-icon>
          <span mat-line>Upload Policy</span>
        </a>
        <a mat-list-item (click)="addhrtab()" *ngIf="superadmin == apirole">
          <mat-icon style="color: #094da3;" mat-list-icon>person_outline</mat-icon>
          <span mat-line>Add Admin</span>
        </a>
        <a mat-list-item (click)="addusertab()" *ngIf="superadmin == apirole || admin == apirole">
          <mat-icon style="color: #094da3;" mat-list-icon>person_add</mat-icon>
          <span mat-line>Add Associate</span>
        </a>
        <a mat-list-item (click)="inactivetab()" *ngIf="superadmin == apirole || admin == apirole">
          <mat-icon style="color: #094da3;" mat-list-icon>people_outline</mat-icon>
          <span mat-line>Inactive Associates</span>
        </a>
        <a mat-list-item (click)="assliststab()" *ngIf="superadmin == apirole || admin == apirole">
          <mat-icon style="color: #094da3;" mat-list-icon>people_outline</mat-icon>
          <span mat-line>Associate List</span>
        </a>
        <a mat-list-item (click)="associateclick()" *ngIf="superadmin == apirole">
          <mat-icon style="color: #094da3;" mat-list-icon>people_outline</mat-icon>
          <span mat-line>Associate  Policy  Details</span>
        </a>
        <a mat-list-item (click)="associatependingtab()" *ngIf="superadmin == apirole">
          <mat-icon style="color: #094da3;" mat-list-icon>person_outline</mat-icon>
          <span mat-line>Associate Pending Policy</span>
        </a>
        <a mat-list-item (click)="Viewprofile()">
          <mat-icon style="color: #094da3;" mat-list-icon>visibility</mat-icon>
          <span mat-line>View Profile</span>
        </a>   

        <a mat-list-item>
          <mat-icon style="color: #094da3;" mat-list-icon>logout</mat-icon>
          <span mat-line (click)="logout()">Logout</span>
        </a>



      </mat-nav-list>



    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content ">


      <mat-toolbar dark style="background-color:#094da3;" class="d-flex justify-content-between">
        <div>
          <button mat-icon-button (click)="sidenav.toggle()" (click)="arrow()">
            <mat-icon style="color: white;" mat-list-icon *ngIf="arrows==true">keyboard_arrow_left</mat-icon>
            <mat-icon style="color: white;" mat-list-icon *ngIf="arrows==false">keyboard_arrow_right</mat-icon>
          </button>
        </div>
        <!-- <div class="ml-3"></div> -->


        <div >
          <!-- <marquee scrollamount="4"> -->
          <div style="color: white;cursor: pointer; display: inline-block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;" class="scrolls mt-3" matTooltip="{{username}}" (click)="Viewprofile()">{{username}}</div>
          <!-- </marquee> -->
        </div>



      </mat-toolbar>

      <div class="main-content">
        <router-outlet>
          <dashboard *ngIf="dashboard && admin != apirole"></dashboard>
          <app-associatelist *ngIf="associate"></app-associatelist>
          <app-uploadpolicy *ngIf="upload  && superadmin == apirole"></app-uploadpolicy>
          <app-viewprofile *ngIf="viewprofile"></app-viewprofile>
          <app-addassociate *ngIf="adduser && (superadmin == apirole || admin == apirole )"></app-addassociate>
          <app-associatependinglist *ngIf="associatepend  && superadmin == apirole"></app-associatependinglist>
          <app-addhr *ngIf="addhr  && superadmin==apirole"></app-addhr>
           <app-associates-lists *ngIf="asslist && superadmin && admin"></app-associates-lists>
           <app-inactive-associateslist *ngIf="active && superadmin && admin "></app-inactive-associateslist>
          
          

          <div>

          </div>

        </router-outlet>
      </div>



    </mat-sidenav-content>




  </mat-sidenav-container>

</div>



<ngx-ui-loader [loaderId]="'loader-03'"></ngx-ui-loader>