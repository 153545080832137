import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
 
} from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { ApiServicesService } from '../api-services.service';
import { ComponentComponent } from '../component/component.component';
import { VALIDATIONS } from '../config';
import { data } from 'jquery';
@Component({
  selector: 'app-addassociate',
  templateUrl: './addassociate.component.html',
  styleUrls: ['./addassociate.component.scss']
})
export class AddassociateComponent implements OnInit {
  registration!: FormGroup;
  gender: any = ["Male", "Female", "Others"]
  submitted = false;
  registerForm: FormGroup;
  constructor(public formBuilder: FormBuilder, private ApiServicesService: ApiServicesService, private router: Router, private component: ComponentComponent, private ngxLoader: NgxUiLoaderService) { }
  ngOnInit(): void {

    this.registerForm = this.formBuilder.group({
      Gender: ['', Validators.required],
      firstName: ['', Validators.required],
      Designation: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
      DOB: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.pattern(VALIDATIONS.MOBILE), Validators.minLength(10)]],
      Employee_Id: ['', [Validators.required, Validators.minLength(10)]],
      otp: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]],
    },);
  }
  register() {
    let data = {
      "employeeId": this.registration.value.employeeId,
      "dateOfBirth": moment(this.registration.value.dateOfBirth).format('DD/MM/YYYY'),
      "email": this.registration.value.email,
      "designation": this.registration.value.designation,
      "gender": this.registration.value.gender,
      "mobile": this.registration.value.mobile,
      "name": this.registration.value.name,
      "organisationId": "1",
      "organisationName": "Bilvantis",
      "oneTimePassword": this.registration.value.oneTimePassword
    }
    let query_param = {}
        
    this.ngxLoader.startLoader('loader-01');
    this.ApiServicesService.Agentregister(data,query_param).subscribe((res: any) => {
      if (res.errorcode == '227') {
        Swal.fire({
          title: '',
          text: 'Emp ID Already Exists',
          icon: 'error',
          confirmButtonText: 'Ok'

        })
        this.ngxLoader.stopLoader('loader-01');
      } else {
        Swal.fire({
          title: '',
          text: 'Associate added Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            this.component.associateclick()
          }
        });
        this.ngxLoader.stopLoader('loader-01');
      }
    })
  }


  get formControls() {
    return this.registration.controls;
  }


  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
      
    }
    
    let data = {
      "employeeId": this.registerForm.value.Employee_Id,
      "dateOfBirth": moment(this.registerForm.value.DOB).format('DD/MM/YYYY'),
      "email": this.registerForm.value.email,
      "designation": this.registerForm.value.Designation,
      "gender": this.registerForm.value.Gender,
      "mobile": this.registerForm.value.mobile,
      "name": this.registerForm.value.firstName,
      "organisationId": "1",
      "organisationName": "Bilvantis",
      "oneTimePassword": this.registerForm.value.otp
    
    }
    this.ngxLoader.startLoader('loader-01');
    let query_param = {
      "adduser": 'Associate',
      "type": 'add',
      "user_Id_Number": sessionStorage.getItem('Id')
    }

    this.ApiServicesService.Agentregister(data,query_param).subscribe((res: any) => {
      if (res.errorcode == '227') {
        Swal.fire({
          title: '',
          text: 'Emp ID Already Exists',
          icon: 'error',
          confirmButtonText: 'Ok'

        })
        this.ngxLoader.stopLoader('loader-01');

      } else {
        Swal.fire({
          title: '',
          text: 'Added Associate Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'

        }).then((result) => {
          if (result.isConfirmed) {
            this.component.assliststab()
          }
        });
        this.ngxLoader.stopLoader('loader-01');

      }


    }, err => {

Swal.fire({
  title: '',
  text: err.error.message,
  icon: 'error',
  confirmButtonText: 'Ok'

})

      this.ngxLoader.stopLoader('loader-01');

    })
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();

    
  }
}
