import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material.module';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './login';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AboutComponent } from './about';
import { NotFoundPageComponent } from './notfoundpage';
import { ConfirmDialog } from './shared/dialog.component';
import { LoadingComponent } from './loading';
import { AuthGuard } from './_guard';
import { ReactiveFormsModule } from '@angular/forms';
import { ApiServicesService } from './api-services.service';
import { ComponentComponent } from './component/component.component';
import { AssociatelistComponent } from './associatelist/associatelist.component';
import { SafePipe } from '../safe.pipe';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { UploadpolicyComponent } from './uploadpolicy/uploadpolicy.component';
import { ViewprofileComponent } from './viewprofile/viewprofile.component';
import { AddassociateComponent } from './addassociate/addassociate.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
// import { NgxUiLoaderConfig, NgxUiLoaderModule, SPINNER } from "ngx-ui-loader";
import { CustomdatePipe } from '../customdate.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AssociatependinglistComponent } from './associatependinglist/associatependinglist.component';
import { AddhrComponent } from './addhr/addhr.component';
import { AssociatesListsComponent } from './associates-lists/associates-lists.component';
import { InactiveAssociateslistComponent } from './inactive-associateslist/inactive-associateslist.component';
import {
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
  NgxUiLoaderModule,
} from "ngx-ui-loader";
import { EditComponent } from './edit/edit.component';
import { EditassociateComponent } from './editassociate/editassociate.component';
import { GettersetterService } from './gettersetter.service';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  logoUrl: "../../assets/logo bil .png",

  blur: 10,
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.rectangleBounce,
  fgsType: SPINNER.rectangleBounce,
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 5,

};
@NgModule({
  declarations: [
    LoginComponent,
    DashboardComponent,
    AboutComponent,
    NotFoundPageComponent,
    ConfirmDialog,
    LoadingComponent,
    AppComponent,
    ComponentComponent, AssociatelistComponent, SafePipe, UploadpolicyComponent, ViewprofileComponent, AddassociateComponent, ForgotpasswordComponent, CustomdatePipe, AssociatependinglistComponent, AddhrComponent, AssociatesListsComponent, InactiveAssociateslistComponent, 
    EditComponent,EditassociateComponent
  ],
  providers: [
    AuthGuard,
    ApiServicesService, ,
    DatePipe,
    ViewprofileComponent,
    GettersetterService,
    EditComponent,

    { provide: LocationStrategy, useClass: HashLocationStrategy },

  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    MaterialModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    PdfViewerModule


  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
