import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { ApiServicesService } from '../api-services.service';
import { BASE_URL } from '../config';
@Component({
  selector: 'app-associatependinglist',
  templateUrl: './associatependinglist.component.html',
  styleUrls: ['./associatependinglist.component.scss']
})
export class AssociatependinglistComponent implements OnInit {
  Policys: any = "Select"
  policydetails: any;
  allpolicys: string;
  associateDetails: any;
  associatepagination: any;
  norecord: any;
  url = BASE_URL.BASE_URL
  associaterole: any
  constructor(private ApiServicesService: ApiServicesService, private ngxLoader: NgxUiLoaderService) { }
  assolist: any = [{ sno: 1, empid: 'BTIN100080', associatename: "test", policy: "policy2" }, { sno: 2, empid: 'BTIN100081', associatename: "test2", policy: "policy3" }, { sno: 3, empid: 'BTIN100083', associatename: "test3", policy: "policy2" }]
  ngOnInit(): void {
    this.associaterole = sessionStorage.getItem('associateid')
    let body = {
      "isFetchingActivePendingPolicy": true
    }
    this.ApiServicesService.policyget(body).subscribe((res: any) => {
      this.policydetails = res?.response
    })
    this.policy()
  }
  policy() {
    if (this.Policys != 'Select') {

      this.ngxLoader.startLoader('loader-01');

      let body = {
        "policyId": this.Policys,
        "policyCompletion": false,


      }

      this.ApiServicesService.associateDetails(body, '').subscribe((res: any) => {
        this.associateDetails = res.response.docsResult
        this.associatepagination = res
        this.norecord = res
        this.allpolicys = ''
        this.ngxLoader.stopLoader('loader-01');
      })
    }
  }


  associateDetail(num: any) {
    this.ngxLoader.startLoader('loader-01');
    let body = {
      "policyId": this.Policys,
      "policyCompletion": false,

    }
    this.ApiServicesService.associateDetails(body, num).subscribe((res: any) => {
      this.ngxLoader.stopLoader('loader-01');
      this.associateDetails = res.response.docsResult
      this.associatepagination = res
    }
    )
  }


  sendemail() {
    let body = {
      "policyCompletion": false,

      "policyId": this.Policys
    }
    Swal.fire({
      title: '',
      text: 'Are You Sure you want to Trigger Email Remainder to Associates',
      icon: 'warning',
      allowOutsideClick: false,
      confirmButtonColor: '#003d7c',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {

        this.ApiServicesService.sendemail(body).subscribe((res: any) => {
          this.ngxLoader.stopLoader('loader-01');

          Swal.fire({
            title: '',
            text: 'Mail sent Successfully',
            icon: 'success',
            confirmButtonText: 'Ok'

          })

        })

      }
    });
  }






  goToLinkpdf() {

    window.open(this.url + 'registration/downloadpdf?id=' + this.associaterole + '&policyId=' + this.Policys + '&policyCompletion=false');

  }

  goToLink() {
    window.open(this.url + 'registration/getexcelfile?policyId=' + this.Policys + '&id=' + this.associaterole + '&policyCompletion=false');
  }


}

